.cookie
  width: 100%
  position: fixed
  bottom: 0
  left: 0
  background-color: #f7f7f7
  z-index: 99
  padding: 30px 0
  text-align: center
  border-top: 1px solid #F3F4F7
  p 
    margin-bottom: 23px
    font: 400 16px/23px ProximaNova, sans-serif
    color: rgba(89, 122, 141, .8)
  .btn 
    box-shadow: 0 4px 6px rgba(34, 34, 34, .15)
  @media (max-width: 480px)
    padding: 10px 0
    p 
      margin-bottom: 15px