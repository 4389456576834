@import '~assets/styles/_vars.sass'
  
.header
  background-repeat: repeat-x
  &_theme
    &_white
      .header
        &__nav_link, 
        &__breadcrumbs_link,
        &__breadcrumbs_logo figcaption
          color: #fff
        &__top
          border-color: rgba(255, 255, 255, .05)
        &__breadcrumbs_link_active
          border-radius: 30px
          background-color: hsla(0, 0%, 100%, .1)
          padding: 6px 18px
    &_white 
      .sectionHeading h2
        color: #fff
      .header
        &__nav
          &Show
            span, span:before, span:after 
              background-color: #fff
  &__top
    border-bottom: 1px solid $border-color
    position: relative
    .langs 
      margin-left: 25px
    &_flex
      display: flex
      align-items: center
      justify-content: space-between
      padding-top: 25px !important
      padding-bottom: 25px !important
  &__home
    font-size: 0
    &Wrp
      display: flex
      align-items: center
  &__nav
    &_items
      display: flex
      align-items: center
    &_item
      position: relative
      z-index: 3
      &:not(:last-of-type)
        margin-right: 37px
      &:hover > .header__nav_dropdown
        transform: translateX(-50%) perspective(1000px) rotateY(0)
        z-index: 9
    &_link
      font: 700 16px ProximaNova, sans-serif
      color: $text-blue
      border-bottom: 2px solid transparent
      transition: all .15s ease
      &:hover
        border-color: $text-blue
      &_active
        border-color: $text-blue
    &_dropdown
      width: 325px
      position: absolute
      top: calc(100% - 3px)
      left: 50%
      z-index: -1
      margin-top: 21px
      box-shadow: 0 0 30px rgba(53, 62, 163, .15)
      border-radius: 10px
      transform: translateX(-50%) perspective(1000px) rotateY(90deg)
      transition: all .25s ease-in
      &:after 
        content: ''
        display: block
        width: 100%
        height: 21px
        position: absolute
        top: 0
        left: 0
        transform: translateY(-100%)
      &:before 
        content: ''
        border-bottom: 10px solid #fff
        border-left: 12px solid transparent
        border-right: 12px solid transparent
        position: absolute
        left: 50%
        top: 0
        transform: translate(-50%, -100%)
      &Item
        &:first-of-type .header__nav_dropdownLink
          border-radius: 10px 10px 0 0
          padding-top: 20px
        &:last-of-type .header__nav_dropdownLink
          border-radius: 0 0 10px 10px
          padding-bottom: 20px
      &Link 
        display: block
        padding: 10px 26px
        background-color: #fff
        &:hover h3 
          padding-left: 8px
        &[data-id="creditConveyer"] h3 
          color: #3726b0
        &[data-id="p2p"] h3 
          color: #3066a1
        &[data-id="depositConveyer"] h3 
          color: #360e6a
        &[data-id="scoring"] h3 
          color: #3D4C66
        &[data-id="dataConnector"] h3 
          color: #2e81c1
        &[data-id="unloadDataInKB"] h3 
          color: #192A64
        &[data-id="callCenter"] h3 
          color: #1a457a
        &[data-id="development"] h3 
          color: #1fc097
        &[data-id="wonderbase"] h3 
          color: #202020
        &[data-id="development"],
        &[data-id="wonderbase"]
          background-color: #f7f7f7
        &[data-id="development"]
          border-top: 1px solid #e8e8e8
        h3 
          font-size: 18px
          margin-bottom: 5px 
          transition: all .15s ease-in-out
        p 
          font-size: 14px
          line-height: 18px
          color: rgba(89, 122, 141, .8)
    &Show
      display: none
      width: 20px
      height: 15px
      position: relative
      span 
        display: block
        height: 3px
        background-color: $indigo
        &:before, &:after 
          content: ''
          display: block
          width: 100%
          height: 3px
          position: absolute
          background-color: $indigo
          left: 0
        &:before 
          top: 0
        &:after 
          bottom: 0
    &Hide 
      width: 30px
      height: 30px
      border-radius: 50%
      background-color: #fff
      position: relative
      &:before, &:after 
        content: ''
        display: block
        width: 15px
        height: 2px
        background-color: #480f75
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        margin: auto
      &:before 
        transform: rotate(45deg)
      &:after 
        transform: rotate(-45deg)
    &Wrp
      &_top
        display: none
  &__bottom
    font-size: 0
    padding: 18px 0 0
    overflow: hidden
  &__breadcrumbs
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 79px
    &_logo
      display: flex
      align-items: center
      figcaption
        font: 700 16px ProximaNova, sans-serif
        margin-left: 15px
    &_items
      display: flex
      align-items: center
    &_item
      padding: 0 30px
      &:not(:last-of-type)
        border-right: 2px solid $border-color
    &_link
      font: 700 16px ProximaNova, sans-serif
      &_active
        color: #F93755
  &__image
    text-align: center
    margin-top: 67px
    &Scoring img   
      border-radius: 8px 8px 0 0
      box-shadow: 0px -20px 144px rgba(29, 44, 98, 0.2)
      background-color: #EDEDF2
      transition: all .25s ease
      &:hover 
        box-shadow: none
    figcaption
        font-size: 0
  @media (max-width: 958px)
    padding-top: 81px
    &_theme
      &_white
        .header
          &__navShow
            span, span:before, span:after
              background-color: $indigo
          &__home
            svg path 
              fill: $indigo
          &__top 
            border-color: $border-color
    &__top
      width: 100%
      position: fixed
      top: 0
      left: 0
      background-color: #fff
      z-index: 8
      &_flex
        padding-top: 15px !important
        padding-bottom: 15px !important
    &__nav
      &_items
        flex-direction: column
        align-items: flex-start
      &_item
        &:not(:last-of-type)
          margin: 0 0 20px
        &:hover .header__nav_dropdown
          transform: translate(0, 0)
      &_link
        font-weight: normal
        font-size: 22px
        color: #fff
      &_dropdown
        border-radius: 0
        margin: 7px 0 0
        position: static
        transform: translate(0, 0)
        border-left: 1px solid hsla(0, 0%, 100%, .5)
        padding: 13px 16px
        &:before, &:after 
          display: none
        &Item 
          &:not(:last-of-type)
            margin-bottom: 18px
        &Link 
          border: none !important
          background: none !important
          padding: 0 !important
          &:hover h3
            padding-left: 0
          h3 
            color: #fff !important
            margin: 0
            font-size: 16px
            line-height: 1
            font-weight: normal
          p 
            display: none
      &Show 
        display: block
      &Wrp
        width: 100vw
        height: 100vh
        position: fixed
        top: 33px
        left: 25px
        z-index: -1
        padding: 25px
        transform-origin: left top
        transition: all .36s ease-in-out
        transform: scale(0)
        background-color: #480f75
        overflow-y: auto
        &_top
          display: flex
        &_isOpen
          transform: scale(1)
          top: 0
          left: 0
          z-index: 9
        &_top
          margin-bottom: 30px
          display: flex
          align-items: center
          justify-content: space-between

  @media (max-width: 600px)
    &__breadcrumbs
      margin-bottom: 76px
      &_logo
        display: none
      &_item
        padding: 0 20px
        &:first-child 
          padding-left: 0

  @media (max-width: 480px)
    padding-top: 71px