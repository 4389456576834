.contacts
  width: 100%
  height: 100vh
  position: fixed
  top: 0
  left: 0
  overflow-y: auto 
  background-image: linear-gradient(90deg, #500f79, #350e6a)
  transition: all .25s ease-in-out
  opacity: 0
  z-index: -1
  &_open
    opacity: 1
    z-index: 9
  &__flex
    display: flex
    justify-content: space-between
    margin-bottom: 56px
  &__top
    padding: 20px
    display: flex
    align-items: center
    justify-content: space-between
  &__bottom
    margin-top: 90px
  &__close
    width: 62px
    height: 62px
    border-radius: 50%
    background-color: #fff
    position: relative
    &:before, &:after 
      content: ''
      display: block
      width: 25px
      height: 2px
      background-color: #3B1772
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0
      margin: auto
      border-radius: 3px
    &:before 
      transform: rotate(45deg)
    &:after 
      transform: rotate(-45deg)
  &__title
    font: 400 36px/44px ProximaNova, sans-serif
    color: #fff
    margin-bottom: 56px
  &__form
    flex-shrink: 0
    width: 745px
    margin-bottom: 56px
    &Row
      display: flex
      margin-bottom: 22px
      label 
        flex-shrink: 0
        font-size: 20px
        width: 295px
        color: #fff
        padding: 10px 10px 10px 0
        small 
          display: block
          margin-top: 15px
          line-height: 23px
          font-size: 14px
    &Actions
      padding-left: 295px
      margin-top: 59px
  &__list
    width: 260px
    &Title
      font: 400 24px/28px ProximaNova, sans-serif
      color: #fff
      margin-bottom: 35px
    &Item 
      color: #fff
      &:not(:last-of-type)
        margin-bottom: 21px
      a 
        font-weight: bold
        font-size: 21px
      span 
        display: block
        margin-top: 3px
  @media (max-width: 1087px)
    &__form
      width: 65%
      &Row
        flex-direction: column
      &Actions 
        padding-left: 0
    &__list
      width: 30%

  @media (max-width: 850px)
    &__flex
      flex-direction: column
    &__form, &__list
      width: 100%
    &__form
      &Row
        flex-direction: column
    &__bottom
      margin-top: 56px

  @media (max-width: 480px)
    &__close
      width: 45px
      height: 45px
      &:before, &:after 
        width: 20px
    &__form
      &Row
        flex-direction: column
        margin-bottom: 10px
      &Actions 
        margin-top: 30px
    &__title
      font: 400 26px/30px ProximaNova, sans-serif
      margin-bottom: 30px
    
