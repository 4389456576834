.langs
  display: flex
  align-items: center
  &Wrp 
    display: flex
    align-items: center
    position: relative
    &_type
      &_dropdown
        margin-left: 20px
        .langs
          max-width: 300px
          min-width: 140px
          display: flex
          flex-direction: column
          position: absolute
          top: 0 
          left: 0
          padding: 10px
          background-color: rgba(0, 0, 0, .7)
          border-radius: 6px
          margin: 0
          transform-origin: left top 
          transform: scale(0)
          z-index: -1
          transition: all .26s ease-in-out
          &__option
            border-radius: 6px
            border-width: 2px
            &:not(:last-of-type)
              margin: 0 0 10px
            &:hover
              border-color: #fff
            &_current
              border-color: #fff
            &_btn
              border-radius: 6px
              padding: 5px 10px
              &:hover 
                border-color: #fff
              span 
                margin-left: 7px
    &_show
      &_dropdown 
        .langs
          top: 35px
          transform: scale(1)
          z-index: 8
  &__btnDropwdown
    display: flex
    align-items: center
    img 
      border: 1px solid #9FA5BD
      border-radius: 4px
      padding: 1px
    &:after 
      content: ''
      display: block
      border-top: 5px solid rgba(205, 196, 227, .8)
      border-left: 5px solid transparent
      border-right: 5px solid transparent
      margin-left: 7px
  &__option
    overflow: hidden
    padding: 1px
    border: 1px solid transparent
    width: 100%
    border: 1px solid transparent
    &_btn
      width: 100%
      display: flex
      align-items: center
      font-size: 18px
      span 
        white-space: nowrap
        color: #fff
    &:not(:last-of-type)
      margin-right: 20px
    &_current
      border-radius: 4px
      border-color: #9FA5BD