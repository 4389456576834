.skeleton
  width: 100%
  max-width: 100% !important
  display: block
  font-size: 14px
  border-radius: 8px
  background-color: #ccc !important
  position: relative
  overflow: hidden
  &:before 
    content: ''
    display: block
    width: 100%
    height: 100%
    background-image: linear-gradient(90deg, #ccc, #dedede, #ccc)
    position: absolute
    left: 0
    top: 0
    animation: shine-lines 1s ease-in-out infinite
  &_type
    &_text:not(:last-of-type)
      margin-bottom: 5px
    &_block, &_circle
      height: 100%
    &_circle
      border-radius: 50%

@keyframes shine-lines
  from
    transform: translateX(-100%)
  to
    transform: translateX(100%)