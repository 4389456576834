.success
  width: 100%
  height: 100vh
  position: fixed
  top: 0
  left: 0
  overflow-y: auto 
  background-image: linear-gradient(90deg, #500f79, #350e6a)
  transition: all .25s ease-in-out
  opacity: 0
  z-index: -1
  display: flex
  flex-direction: column
  &_open
    opacity: 1
    z-index: 9
  &__top
    padding: 20px
    display: flex
    align-items: center
    justify-content: space-between
  &__close
    width: 62px
    height: 62px
    border-radius: 50%
    background-color: #fff
    position: relative
    &:before, &:after 
      content: ''
      display: block
      width: 25px
      height: 2px
      background-color: #3B1772
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0
      margin: auto
      border-radius: 3px
    &:before 
      transform: rotate(45deg)
    &:after 
      transform: rotate(-45deg)
  &__bottom
    flex-grow: 1
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    text-align: center
    color: #fff
    padding: 20px
    h3 
      font: 400 36px/44px ProximaNova, sans-serif
      color: #fff
      margin-bottom: 0
    p 
      margin: 20px 0