.iconCircle
	flex-shrink: 0
	width: 80px
	height: 80px
	border-radius: 50%
	display: flex
	justify-content: center
	align-items: center
	padding: 12px
	&_size
		&_big
			width: 120px
			height: 120px
	@media (max-width: 480px)
		&_size 
			&_big 
				width: 80px
				height: 80px
				padding: 19px