/* ============ Normilize ============*/
html
  line-height: 1.15
  -webkit-text-size-adjust: 100%

button, input, optgroup, select, textarea
  font-family: inherit
  font-size: 100%
  line-height: 1.15
  margin: 0

button, input
  overflow: visible

button,
select
  text-transform: none

button,
[type="button"],
[type="reset"],
[type="submit"]
  -webkit-appearance: button

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner
  border-style: none
  padding: 0

textarea
  overflow: auto

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button
  height: auto

[type="search"]
  -webkit-appearance: textfield
  outline-offset: -2px

[type="search"]::-webkit-search-decoration
  -webkit-appearance: none

::-webkit-file-upload-button
  -webkit-appearance: button
  font: inherit
/* ============ // Normilize ============*/