/* ============ Normilize ============*/
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* ============ // Normilize ============*/
/* ============ Reset ============*/
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6,
div, p, span, pre, iframe,
a, address, img, strong,
i, ul, li, figcaption, figure, form, fieldset, label, textarea,
input, button, table, thead,
tbody, tr, th, td, canvas, header,
section, aside, footer, audio, video, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

table {
  border-collapse: collapse;
}

textarea {
  resize: none;
}

textarea,
input {
  outline: none;
}

input::-ms-clear {
  display: none;
}

button {
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
}

fieldset {
  border: none;
}

legend {
  padding: 0;
}

section {
  position: relative;
}

img {
  max-width: 100%;
}

/* ============ // reset ============*/
@font-face {
  font-family: "ProximaNova";
  src: url("~assets/fonts/ProximaNova-Regular/ProximaNova-Regular.woff") format("woff");
  src: url("~assets/fonts/ProximaNova-Regular/ProximaNova-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("~assets/fonts/ProximaNova-Semibold/ProximaNova-Semibold.woff") format("woff");
  src: url("~assets/fonts/ProximaNova-Semibold/ProximaNova-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "ProximaNova";
  src: url("~assets/fonts/ProximaNova-Bold/ProximaNova-Bold.woff") format("woff");
  src: url("~assets/fonts/ProximaNova-Bold/ProximaNova-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
body {
  display: flex;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font: 400 16px ProximaNova, sans-serif;
  color: #506593;
  background: #fff;
}

main {
  flex-grow: 1;
  padding-bottom: 120px;
}
main section:not([data-without-default-border=true]):not(:last-of-type):after {
  content: "";
  display: block;
  width: 1180px;
  height: 2px;
  background-color: #F3F4F7;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1195px) {
  main section:not([data-without-default-border=true]):not(:last-of-type):after {
    width: calc(100% - 50px);
  }
}
@media (max-width: 768px) {
  main section:not([data-without-default-border=true]):not(:last-of-type):after {
    width: calc(100% - 38px);
  }
}
@media (max-width: 480px) {
  main {
    padding-bottom: 60px;
  }
}

p {
  font: 400 18px/30px ProximaNova, sans-serif;
}

h1, h2, h3, h4 {
  font-weight: 700;
  color: #1D2C62;
  margin-bottom: 15px;
}

h2 {
  font-size: 48px;
  line-height: 60px;
}

h3 {
  font-size: 24px;
  line-height: 20px;
}

h4 {
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 480px) {
  p {
    font: 400 16px/20px ProximaNova, sans-serif;
  }
  h2 {
    font: 700 30px/40px ProximaNova, sans-serif;
  }
}
hr {
  border: none;
  height: 2px;
  background-color: #F3F4F7;
}

.container {
  width: 1180px;
  margin: 0 auto;
}
@media (max-width: 1195px) {
  .container {
    width: 100%;
    padding: 0 25px;
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0 19px;
  }
}

.slick-slide * {
  outline: none;
}
.slick-dots {
  width: 100%;
  text-align: center;
  font-size: 0;
  position: static;
  margin-top: 18px;
}
.slick-dots li {
  display: inline-block;
  width: auto;
  height: auto;
}
.slick-dots li:not(:last-of-type) {
  margin: 0 10px 0 0;
}
.slick-dots li[class=slick-active] button {
  opacity: 1;
}
.slick-dots li button {
  font-size: 0;
  width: 10px;
  height: 10px;
  background-color: #1D2C62;
  opacity: 0.1;
  border-radius: 50%;
}
.slick-dots li button:before {
  display: none;
}

.Toastify__toast {
  border-radius: 10px;
  padding: 10px;
}
.Toastify__toast-body {
  font-weight: bold;
  padding: 5px 10px 5px 0;
}

#nprogress .bar {
  display: none;
}
#nprogress .spinner {
  position: fixed;
  right: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}