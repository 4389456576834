@import '~assets/styles/_vars.sass'

.footer
  padding: 50px 0 24px
  background-color: #F6F6F9
  p 
    font-size: 14px
    line-height: 20px
  &__flex
    display: flex
    justify-content: space-between
    &:not(:last-of-type)
      margin-bottom: 43px
  &__about
    flex-shrink: 0
    width: 199px
    .langs
      margin-top: 24px
  &__contacts
    width: 280px
  &__logo
    margin-bottom: 25px
  &__nav
    flex-shrink: 0
    width: 137px
    &_item
      &:not(:last-of-type)
        margin-bottom: 17px
  &__phone
    display: inline-block
    margin-bottom: 10px
    color: $red
    font-weight: bold
    font-size: 20px
    color: $red
  &__social
    display: flex
    align-items: center
    &_item
      font-size: 0
      &:not(:last-of-type)
        margin-right: 30px
    &Wrp
      flex-grow: 1
      display: flex
      justify-content: space-between
      align-items: center
  &__langs
    width: 199px
    margin-right: 73px
    position: relative
    .langs
      display: none
    p 
      line-height: 1
  &__partner
    font-size: 14px
    color: #A3ADC6
    width: 280px
    a 
      font-weight: 600
      color: $indigo
  @media (max-width: 1046px)
    &__flex
      flex-wrap: wrap
    &__langs
      .langs
        position: static
        transform: translateY(0)
        margin-bottom: 20px

  @media (max-width: 939px)
    &__contacts
      margin-top: 15px
  
  @media (max-width: 780px)
    &__about
      display: none
    &__partner
      width: 100%
    &__nav, &__contacts
      width: 50%
      margin: 0 0 25px
    &__langs, &__socialWrp
      margin: 0
      width: 50%
    &__langs 
      .langs
        display: flex
    &__social
      &Wrp
        flex-direction: column
        align-items: flex-start
