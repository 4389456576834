/* ============ Reset ============*/
body
	margin: 0
	padding: 0
	box-sizing: border-box

h1, h2, h3, h4, h5, h6,
div, p, span, pre, iframe,
a, address, img, strong, 
i, ul, li, figcaption, figure, form, fieldset, label, textarea,
input, button, table, thead,
tbody, tr, th, td, canvas, header,
section, aside, footer, audio, video, *:before, *:after
	margin: 0
	padding: 0
	box-sizing: border-box
	font: inherit
	color: inherit	

h1, h2, h3, h4, h5, h6
	font-weight: normal

a 
	text-decoration: none

ul
	list-style-type: none

table
	border-collapse: collapse

textarea
	resize: none

textarea,
input
	outline: none

input::-ms-clear
	display: none

button
	outline: none
	background-color: transparent
	cursor: pointer
	border: none

fieldset
	border: none

legend
	padding: 0

section
	position: relative

img 
	max-width: 100%
/* ============ // reset ============*/