.typingAnimate
  &__cursor
    display: inline-block
    line-height: 1
    padding-left: 5px
    border-right: 2px solid transparent
    animation-name: fadeInOut
    animation-iteration-count: infinite

@keyframes fadeInOut
  0%
    border-color: transparent
  50%
    border-color: #F93755
  80%
    border-color: transparent
