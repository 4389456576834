.slick
  &-slide *
    outline: none
  &-dots
    width: 100%
    text-align: center
    font-size: 0
    position: static
    margin-top: 18px
    li 
      display: inline-block
      width: auto 
      height: auto
      &:not(:last-of-type)
        margin: 0 10px 0 0
      &[class="slick-active"] button
        opacity: 1
      button
        font-size: 0
        width: 10px
        height: 10px
        background-color: $indigo
        opacity: 0.1
        border-radius: 50%
        &:before 
          display: none