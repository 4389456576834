.textarea
  width: 100%
  border: none
  height: 176px
  padding: 20px
  border-radius: 4px
  background-color: hsla(0, 0%, 100%, .8)
  font-size: 16px
  color: #384144
  transition: all .25s ease 
  &:focus 
    background-color: #fff