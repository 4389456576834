body
	display: flex
	overflow-x: hidden

#root
	display: flex
	flex-direction: column
	width: 100%
	min-height: 100vh
	font: 400 16px ProximaNova, sans-serif
	color: $text-blue
	background: #fff

main
	flex-grow: 1
	padding-bottom: 120px
	section:not([data-without-default-border="true"]):not(:last-of-type)
		&:after
			content: ''
			display: block
			width: 1180px
			height: 2px
			background-color: $border-color
			position: absolute
			bottom: 0
			left: 50%
			transform: translateX(-50%)
	@media (max-width: 1195px)
		section:not([data-without-default-border="true"]):not(:last-of-type)
			&:after
				width: calc(100% - 50px)

	@media (max-width: 768px)
		section:not([data-without-default-border="true"]):not(:last-of-type)
			&:after
				width: calc(100% - 38px)

	@media (max-width: 480px)
		padding-bottom: 60px

p
	font: 400 18px/30px ProximaNova, sans-serif

h1, h2, h3, h4
	font-weight: 700
	color: $indigo
	margin-bottom: 15px

h2
	font-size: 48px
	line-height: 60px

h3
	font-size: 24px
	line-height: 20px

h4
	font-size: 16px
	line-height: 24px

@media (max-width: 480px)
	p
		font: 400 16px/20px ProximaNova, sans-serif
	h2
		font: 700 30px/40px ProximaNova, sans-serif

hr
	border: none
	height: 2px
	background-color: $border-color

.container
	width: 1180px
	margin: 0 auto
	@media (max-width: 1195px)
		width: 100%
		padding: 0 25px

	@media (max-width: 768px)
		padding: 0 19px
