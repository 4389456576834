.input
  width: 100%
  border: none
  padding: 0 20px
  border-radius: 4px
  background-color: hsla(0, 0%, 100%, .8)
  color: #384144
  transition: all .25s ease 
  &:focus 
    background-color: #fff
  &_size
    &_default 
      height: 48px
      font-size: 16px
    &_big 
      height: 70px
      font-size: 18px
  @media (max-width: 480px)
    &_size
      &_big 
        font-size: 16px
        height: 48px