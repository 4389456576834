@import '~assets/styles/_vars.sass'
  
.btn 
  border-radius: 8px
  transition: all .15s ease
  &:hover
    opacity: .8
  &:not(:disabled):hover 
    animation: tp-rotate 0.7s ease-in-out both
    span 
      display: block
      animation: tp-storm 0.7s ease-in-out both
      animation-delay: 0.06s
  &:disabled 
    opacity: .8
    cursor: no-drop
  span 
    text-transform: uppercase
  &_theme
    &_red
      color: #fff
      background-color: $red
    &_white
      color: #384144
      background-color: #fff
      padding: 17px 42px
    &_green
      background-color: #2EBB89
      color: #fff
  &_size 
    &_default 
      font: 700 13px/16px ProximaNova, sans-serif
      padding: 17px 32px
    &_big
      font: 700 15px/18px ProximaNova, sans-serif
      padding: 17px 40px
  @media (max-width: 480px)
    &_size
      &_default,  &_big
        min-width: 84px
        padding: 12px 10px
      &_big
        font-size: 14px
        padding: 15px 10px


@keyframes tp-rotate 
  0%
    transform: rotate(0deg) translate3d(0,0,0)
  25%
    transform: rotate(3deg) translate3d(0,0,0)
  50%
    transform: rotate(-3deg) translate3d(0,0,0)
  75%
    transform: rotate(1deg) translate3d(0,0,0)
  100%
    transform: rotate(0deg) translate3d(0,0,0)

@keyframes tp-storm 
  0%
    transform: translate3d(0,0,0) translateZ(0)
  25%
    transform: translate3d(4px,0,0) translateZ(0)
  50%
    transform: translate3d(-3px,0,0) translateZ(0)
  75%
    transform: translate3d(2px,0,0) translateZ(0)
  100%
    transform: translate3d(0,0,0) translateZ(0)
    
